<template>
	<div>
		<ProductPopup :data="selectedProduct" :get-product="getAndUpdateProducts" />
		<b-card>
			<div>
				<ProductTypeSwitch :product-type-switch.sync="productTypeSwitch" />
			</div>
			<b-table
				:fields="columns"
				:items="computedProductsList"
				:busy="isTableBusy"
				bordered
				hover
				responsive
				class="shadow-sm rounded products-table"
				thead-tr-class="order-main-thead"
				primary-key="id"
				show-empty
				empty-text="No matching records found"
				small
				@row-clicked="onRowClick"
			/>
			<Pagination
				:fetch-data="getProducts"
				fetch-type="review-products"
				:total-rows="totalRows"
				:take.sync="take"
			/>
		</b-card>
	</div>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';
import ProductPopup from './ProductPopup.vue';
import 'vue-good-table/dist/vue-good-table.css';
import Pagination from '../components/Pagination/Pagination.vue';
import ProductTypeSwitch from './components/ProductTypeSwitch.vue';

export default {
	components: {
		BCard,
		BTable,
		ProductPopup,
		Pagination,
		ProductTypeSwitch,
	},
	filters: {
		formatDate(value) {
			return moment(String(value)).format('HH:mm  MM/DD/YYYY');
		},
	},
	data() {
		return {
			productTypeSwitch: 'pending',
			pageLength: 10,
			dir: false,
			productsList: [],
			isTableBusy: false,
			action: null,
			selectedProduct: null,
			selectedAlghorithmDescriptors: null,
			take: 20,
			skip: 0,
			totalRows: 0,
			productStatusList: {
				1: 'Pending',
				2: 'Rejected',
				3: 'Approved',
				4: 'Edited',
			},
			columns: [
				{
					label: 'Name',
					key: 'nameGeo',
					formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
					tdClass: 'nameColumn',
				},
				{
					key: 'price',
					label: 'Price',
					sortable: true,
					formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
				},
				{
					key: 'price',
					label: 'Price',
					sortable: true,
					formatter: (value, key, item) => `${item.price.toFixed(2)} ₾`,
				},
				{
					key: 'count',
					label: 'Count',
				},
				{
					key: 'commissionFeePercent',
					label: 'Comission',
				},
				{
					label: 'Created at',
					key: 'createdAt',
					sortable: false,
				},
				{
					label: 'Category',
					key: 'productCategory',
					formatter: (value) =>
						`${value.productCategoryNameGeo} / ${value.productCategoryNameEng}`,
					sortable: false,
				},
				{
					label: 'Sub Category',
					key: 'productSubCategory',
					formatter: (value) =>
						`${value.productSubCategoryNameGeo} / ${value.productSubCategoryNameEng}`,
					sortable: false,
				},
				{
					label: 'Generic Category',
					key: 'productGenericCategory',
					formatter: (value) =>
						`${value.productGenericCategoryNameGeo} / ${value.productGenericCategoryNameEng}`,
					sortable: false,
				},
				{
					label: 'SKU',
					key: 'upcOrSku',
				},
			],
			searchTerm: '',
		};
	},
	computed: {
		...mapState('products', ['products', 'algorithmDescriptors']),
		computedProductsList() {
			if (this.productTypeSwitch === 'pending') {
				return this.productsList.filter(
					(e) => e.status === 1 || e.status === 4
				);
			}
			return this.productsList.filter((e) => e.status === 2);
		},
	},
	created() {
		this.getAndUpdateProducts();
	},
	methods: {
		...mapActions('products', [
			'getProducts',
			'deleteProductById',
			'setProductStatus',
			'getAlgorithmDescriptors',
		]),
		getAndUpdateProducts() {
			this.isTableBusy = true;
			this.getProducts({
				skip: this.skip,
				take: this.take,
				searchKey: '',
				productType: 'review-products',
			}).then(() => {
				this.isTableBusy = false;
				this.productsList = this.products.products;
				this.totalRows = this.products.count;
			});
		},
		removeProductButton(prod) {
			const { id } = prod;
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.deleteProductById(id).then(() => {
						this.getProducts({
							skip: this.skip,
							take: this.take,
							searchKey: '',
							productType: 'review-products',
						});
					});
				}
			});
		},
		onRowClick(item) {
			this.selectedProduct = item;
			this.$bvModal.show('product-show-modal');
		},
	},
};
</script>
<style lang="scss" scoped>
.buttonsWrapper {
	display: flex;
	justify-content: space-between;
	@media (max-width: 600px) {
		flex-direction: column;
	}
}
.custom-search {
	display: flex;
	justify-content: flex-end;
	@media (max-width: 600px) {
		width: 100%;
		justify-content: flex-start;
	}
}
.form-group {
	@media (max-width: 600px) {
		width: 100%;
	}
}
.scrollme {
	overflow-x: auto;
	max-height: 200px;
	max-width: 50%;
}
.products-table th,
.products-table td {
	// min-width: 200px ;
	white-space: nowrap;
}
</style>
