<template>
	<b-modal
		id="product-show-modal"
		ref="product-show-modal"
		scrollable
		hide-footer
		size="xl"
		title="Product details"
		@shown="getData"
		@hide="hideModal"
	>
		<div v-if="productData">
			<b-col>
				<div v-if="productData.mainImage">
					<label for="">Main image</label>
					<div>
						<a :href="productData.mainImage.url" target="_blank">
							<img :src="productData.mainImage.url" class="popup-gallery-img" />
						</a>
					</div>
				</div>
				<div v-if="productData.gallery.length">
					<label for="">Gallery images</label>
					<div class="d-flex flex-wrap">
						<img
							v-for="img in productData.gallery"
							:key="img.id"
							:src="img.url"
							class="popup-gallery-img"
						/>
					</div>
				</div>

				<h2 class="d-flex justify-content-center mt-1">About Product:</h2>
			</b-col>
			<b-card>
				<h6 class="mb-2">Basic Information</h6>
				<b-row>
					<b-col md="3">
						<label>Name Geo:</label>
						<p>{{ productData.nameGeo }}</p>
					</b-col>
					<b-col md="3">
						<label>Name Eng:</label>
						<p>{{ productData.nameEng }}</p>
					</b-col>
					<b-col md="3">
						<label>SKUT:</label>
						<p>{{ productData.upcOrSku }}</p>
					</b-col>
					<b-col sm="3">
						<label>Service type:</label>
						<p>{{ serviceTypeList[productData.serviceType] }}</p>
					</b-col>
					<b-col sm="3">
						<label>Gift type:</label>
						<p>{{ giftTypeList[productData.giftType] }}</p>
					</b-col>
					<b-col sm="3">
						<label>Category:</label>
						<p>
							{{
								`${productData.productCategory.productCategoryNameGeo} /
									${productData.productCategory.productCategoryNameEng}`
							}}
						</p>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Branches" label-for="branches">
							<b-badge
								v-for="branch in productData.branches"
								:key="branch.id"
								pill
								variant="info"
							>
								{{ `${branch.nameEng} / ${branch.nameGeo}` }}
							</b-badge>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<h6>Characteristics</h6>
						<b-row>
							<b-col sm="3">
								<label>Min age:</label>
								<p>{{ productData.minAge }}</p>
							</b-col>
							<b-col sm="3">
								<label>Max age:</label>
								<p>{{ productData.maxAge }}</p>
							</b-col>
							<b-col sm="3">
								<label>Target Gender:</label>
								<p>{{ genderLabel[productData.productTargetGender] }}</p>
							</b-col>
						</b-row>
					</b-col>
					<b-col sm="3">
						<b-form-group label="Commission type" label-for="i-name-eng">
							<b-form-select
								v-model="productData.commissionFeeType"
								:options="commissionTypes"
							/>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group label="Emoty Commission Fee %" label-for="i-name-eng">
							<b-form-input
								id="i-eng"
								v-model="productData.emotyPercentCommissionValue"
                type="number"
								placeholder="Fee here..."
							/>
						</b-form-group>
					</b-col>
          <b-col sm="3">
            <b-form-group label="Markup Fee %" label-for="i-name-eng">
              <b-form-input
                  id="i-eng"
                  v-model="productData.markUpPercentCommissionValue"
                  type="number"
                  placeholder="Fee here..."
              />
            </b-form-group>
          </b-col>
				</b-row>
			</b-card>
			<b-card class="mb-2">
				<h6 class="mb-2">Location</h6>
				<b-row>
					<b-col md="3">
						<label>GPS Location:</label>
						<p>{{ productData.location }}</p>
					</b-col>
					<b-col sm="3">
						<label>City:</label>
						<p>
							{{
								`${productData.city.cityNameGeo} / ${productData.city.cityNameEng}`
							}}
						</p>
					</b-col>
					<b-col sm="3">
						<label>City:</label>
						<p>
							{{
								`${productData.district.districtNameEng} / ${productData.district.districtNameEng}`
							}}
						</p>
					</b-col>
				</b-row>
			</b-card>
			<b-card>
				<h6 class="mb-2">Product Description</h6>
				<b-row>
					<b-col md="6">
						<label>Description Geo:</label>
						<p>{{ productData.descriptionGeo }}</p>
					</b-col>
					<b-col md="6">
						<label>Description Eng:</label>
						<p>{{ productData.descriptionEng }}</p>
					</b-col>
					<b-col md="6">
						<label>Terms of use Geo:</label>
						<p>{{ productData.useTermsGeo }}</p>
					</b-col>
					<b-col md="6">
						<label>Terms of use Eng:</label>
						<p>{{ productData.useTermsEng }}</p>
					</b-col>
					<b-col md="3">
						<label>Active From:</label>
						<p>{{ productData.activeFrom ? productData.activeFrom.slice(0, 16) : '' }}</p>
					</b-col>
					<b-col md="3">
						<label>Active To:</label>
						<p>{{ productData.activeTo ? productData.activeTo.slice(0, 16) : '' }}</p>
					</b-col>
					<b-col v-if="productData.sale" md="3">
						<label>Sale:</label>
						<p v-if="productData.sale.type === 1">
							{{ productData.sale.fixed }} Fixed
						</p>
						<p v-else>{{ productData.sale.percent }} %</p>
					</b-col>
					<b-col v-if="productData.saleUntilAt" md="3">
						<label>Sale unit at:</label>
						<p>{{ productData.saleUntilAt ? productData.saleUntilAt.slice(0, 16) : '' }}</p>
					</b-col>
				</b-row>
			</b-card>
			<app-collapse>
				<app-collapse-item
					v-if="productData.productAttributes"
					title="Attributes"
					class="mb-2"
				>
					<div
						v-for="attribute in productData.productAttributes"
						:key="attribute.id"
						class="p-1 border my-1"
					>
						<p class="mb-0">
							{{ attribute.nameGeo }} / {{ attribute.nameEng }}
						</p>
						<small class="mb-0">
							{{ attribute.value.valueEng }} /
							{{ attribute.value.valueGeo }}
						</small>
					</div>
				</app-collapse-item>
				<app-collapse-item
					v-if="productData.attributes"
					title="Attributes"
					class="mb-2"
				>
					<div
						v-for="attribute in productData.attributes"
						:key="attribute.id"
						class="p-1 border my-1"
					>
						<p class="mb-0">
							{{ attribute.nameGeo }} / {{ attribute.nameEng }}
						</p>
						<small class="mb-0">
							{{ attribute.value.valueEng }} /
							{{ attribute.value.valueGeo }}
						</small>
					</div>
				</app-collapse-item>
				<app-collapse-item
					v-if="productData.configurations"
					title="Product Configuration"
					class="mb-2"
				>
					<app-collapse
						v-for="item in productData.configurations"
						:key="item.productSubCategoryId"
						type="margin"
						accordion
					>
						<app-collapse-item :title="item.colorCode">
							<div
								v-if="item.mainImage"
								class="image-wrapper"
								style="max-width: 300px"
							>
								<b-form-group label="Product Main Image" label-for="i-name-eng">
									<img
										:src="item.mainImage.url"
										alt="main image"
										style="max-width: 300px"
									/>
								</b-form-group>
							</div>
							<b-form-group
								v-if="item.gallery.length"
								label="Product Gallery"
								label-for="i-name-eng"
							>
								<Swiper :images="item.gallery" />
							</b-form-group>
							<b-form-group label="Product Options" label-for="i-name-eng">
								<b-list-group v-for="option in item.options" :key="option.id">
									<b-list-group-item>{{
										`Size: ${option.sizeOption}, UPC/SKU: ${option.upcOrSku}, Quantity: ${option.count}`
									}}</b-list-group-item>
								</b-list-group>
							</b-form-group>
						</app-collapse-item>
					</app-collapse>
				</app-collapse-item>
			</app-collapse>
			<b-card v-if="!isApproved">
				<div class="descriptorsWrapper">
					<b-form-group
						v-for="alghoritm in algorithmDescriptorsData"
						:key="alghoritm.id"
						class="mr-1 mt-auto"
						:label="alghoritm.descriptorGeo"
					>
						<b-form-input
							v-model="alghoritm.value"
							type="number"
							:number="true"
							placeholder="0"
						/>
					</b-form-group>
				</div>
			</b-card>
			<span v-if="!isApproved">
				<b-button
					size="sm"
					class="mr-1"
					variant="success"
					@click="acceptProduct()"
				>
					<span>Accept</span>
				</b-button>
				<b-button size="sm" variant="danger" @click="rejectProduct()">
					<span>Reject</span>
				</b-button>
			</span>
		</div>
	</b-modal>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';

import {
	BButton,
	BCol,
	BRow,
	BFormGroup,
	BFormInput,
	BListGroup,
	BListGroupItem,
	BFormSelect,
	BCard,
	BBadge,
} from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';
import Swiper from './Swiper.vue';

export default {
	components: {
		BButton,
		Swiper,
		BCol,
		BCard,
		BFormSelect,
		BBadge,
		BRow,
		BListGroup,
		BListGroupItem,
		AppCollapse,
		BFormInput,
		BFormGroup,
		AppCollapseItem,
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
		getProducts: {
			type: Function,
			default: () => true,
		},
		isApproved: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			productData: null,
			algorithmDescriptorsData: null,
			selectedServiceType: 1,
			serviceTypeList: {
				1: 'Experiential',
				2: 'Material',
				3: 'Digital',
			},
			genderLabel: {
				1: 'Male',
				2: 'Female',
				3: 'Both',
			},
			giftTypeList: {
				1: 'Both',
				2: 'Daily',
				3: 'Holiday',
			},
			commissionTypes: [
        {
          value: 0,
          text: 'Both',
        },
				{
					value: 1,
					text: 'Emoty',
				},
				{
					value: 2,
					text: 'Markup',
				},
			],
		};
	},
	computed: {
		...mapState('products', ['products', 'algorithmDescriptors']),
	},
	watch: {
		data(newVal) {
			this.productData = newVal;
			console.log(this.productData);
		},
		algorithmDescriptors(newVal) {
			this.algorithmDescriptorsData = newVal;
		},
	},
	methods: {
		...mapActions('products', ['setProductStatus', 'getAlgorithmDescriptors']),
		getData() {
			this.getAlgorithmDescriptors().then(() => {
				this.algorithmDescriptorsData = this.algorithmDescriptors;
			});
		},
		hideModal() {
			this.$refs['product-show-modal'].hide();
		},
		acceptProduct() {
			if (!this.algorithmDescriptorsData) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Invalid inputs',
				});
			} else {
				Swal.fire({
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#28a745',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Accept it!',
				}).then((result) => {
					if (result.isConfirmed) {
						this.setProductStatus({
							id: this.productData.id,
							data: {
								status: 3,
								descriptors: this.algorithmDescriptorsData,
								commissionFeeType: this.productData.commissionFeeType,
                emotyPercentCommissionValue: this.productData.emotyPercentCommissionValue,
                markUpPercentCommissionValue: this.productData.markUpPercentCommissionValue,
							},
						}).then(() => {
							this.hideModal();
							this.getProducts();
						});
					}
				});
			}
		},
		rejectProduct() {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, reject it!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.setProductStatus({
						id: this.productData.id,
						data: {
							status: 2,
						},
					}).then(() => {
						this.getProducts();
					});
				}
			});
		},
	},
};
</script>

<style lang="scss">
.buttons-wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;

	button {
		width: 47%;
	}
}
.feather-24 {
	width: 16px;
	height: 16px;
}
.image-wrapper {
	margin: 0 auto;
	width: auto !important;
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	> img {
		width: 100%;
	}
}
.select-wrapper {
	width: 100%;
}
.inputs-wrapper {
	display: flex;
	flex-wrap: wrap;
}
.descriptorsWrapper {
	display: grid !important;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
	// justify-content: space-between;
}

.popup-gallery-img {
	max-height: 125px;
	margin: 0.5rem;
	border-radius: 15px;
}
</style>
