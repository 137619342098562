<template>
	<div class="filters d-flex justify-content-between align-items-end">
		<b-form-group
			label="Per page"
			class="form-group mr-1"
			label-for="per-page-select"
		>
			<b-form-select
				id="per-page-select"
				v-model="perPage"
				:options="perPageOptions"
			></b-form-select>
		</b-form-group>
		<b-pagination
			v-model="currentPage"
			:total-rows="totalRows"
			:per-page="perPage"
		></b-pagination>
	</div>
</template>

<script>
import { BPagination, BFormGroup, BFormSelect } from 'bootstrap-vue';

export default {
	components: {
		BFormSelect,
		BPagination,
		BFormGroup,
	},
	props: {
		totalRows: {
			type: Number,
			default: 1,
		},
		take: {
			type: Number,
			default: 20,
		},
		fetchData: {
			type: Function,
			default: () => {
				return true;
			},
		},
		fetchType: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			perPageOptions: [10, 20, 50, 100],
			currentPage: 1,
		};
	},
	computed: {
		perPage: {
			get() {
				return this.take;
			},
			set(val) {
				this.$emit('update:take', val);
			},
		},
	},
	watch: {
		perPage(newVal) {
			this.fetchData({
				skip: 0,
				take: newVal,
				searchKey: '',
				productType: this.fetchType ? this.fetchType : '',
			});
		},
		currentPage(newVal) {
			this.fetchData({
				skip: (newVal - 1) * this.take,
				take: this.take,
				productType: 'products',
			});
		},
	},
};
</script>
